const actions = {
  TOGGLE_WORK_MODE: 'toogle_work_mode',
  TOGGLE_JSON_MODE: 'toggle_json_mode',
  TOGGLE_DARK_THEME: 'toggle_dark_theme',
  TOGGLE_REVERSE: 'toggle_reverse',
  TOGGLE_IMAGES: 'toggle_images',
  INCREASE_WIDTH: 'increase_width',
  DECREASE_WIDTH: 'decrease_width',
  INCREASE_TEXT_SIZE: 'increase_text_size',
  DECREASE_TEXT_SIZE: 'decrease_text_size',
  SHOW_TODAY: 'show_today',
  SHOW_PRINT_EDITION: 'show_print_edition',
  SET_PASSWORD: 'set_password',
  SET_OPTIONS: 'set_options',
  SET_LOCAL_STORAGE: 'set_local_storage',
  CHECK_FOR_CACHED_PASSWORD: 'check_for_cached_password',
  CHECK_FOR_CACHED_NEWS: 'check_for_cached_news',
  SET_TODAYS_NEWS_LIST: 'set_todays_news_list',
  SET_PRINT_NEWS_LIST: 'set_print_news_list',
};

export default actions;