import React, { createContext, useReducer } from 'react';

import actions from './actions';

const initialState = {
  printEdition: false,
  darkTheme: false,
  textSize: 18,
  workMode: false,
  jsonMode: false,
  jsonBodyMode: false,
  noImages: false,
  reverse: false,
  maxWidth: 950,
  password: '',
  localStorage: null,
  todaysNewsList: [],
  printNewsList: [],
};

const store = createContext(initialState);
const { Provider, Consumer } = store;

const StateProvider = ({ children }) => {
  let newState;
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case actions.TOGGLE_DARK_THEME:
        newState = {
          ...state,
          darkTheme: !state.darkTheme,
        };
        return newState;
      case actions.TOGGLE_WORK_MODE:
        newState = {
          ...state,
          workMode: !state.workMode,
        };
        return newState;
      case actions.TOGGLE_JSON_MODE:
        if (!state.jsonMode && !state.jsonBodyMode) {
          newState = {
            ...state,
            jsonMode: true,
            jsonBodyMode: true,
          }
          return newState;
        } else if (state.jsonMode && state.jsonBodyMode) {
          newState = {
            ...state,
            jsonMode: true,
            jsonBodyMode: false,
          }
          return newState;
        };

        newState = {
          ...state,
          jsonMode: false,
          jsonBodyMode: false,
        };
        return newState;
      case actions.TOGGLE_IMAGES:
        newState = {
          ...state,
          noImages: !state.noImages,
        };
        return newState;
      case actions.TOGGLE_REVERSE:
        newState = {
          ...state,
          reverse: !state.reverse,
        };
        return newState;
      case actions.INCREASE_WIDTH:
        newState = {
          ...state,
          maxWidth: state.maxWidth >= 1350 ? 650 : state.maxWidth + 50,
        };
        return newState;
      case actions.DECREASE_WIDTH:
        newState = {
          ...state,
          maxWidth: state.maxWidth <= 650 ? 1350 : state.maxWidth - 50,
        }
        return newState;
      case actions.INCREASE_TEXT_SIZE:
        newState = {
          ...state,
          textSize: state.textSize + 1,
        };
        return newState;
      case actions.DECREASE_TEXT_SIZE:
        newState = {
          ...state,
          textSize: state.textSize === 1 ? 1 : state.textSize - 1,
        }
        return newState;
      case actions.SHOW_PRINT_EDITION:
        newState = {
          ...state,
          printEdition: true,
        };
        return newState;
      case actions.SHOW_TODAY:
        newState = {
          ...state,
          printEdition: false,
        };
        return newState;
      case actions.SET_PASSWORD:
        state.localStorage.setItem('economistApiPassword', action.payload);
        return {
          ...state,
          password: action.payload,
        };
      case actions.SET_LOCAL_STORAGE:
        return {
          ...state,
          localStorage: action.payload,
        };
      case actions.SET_OPTIONS:
        newState = { ...state };
        Object.keys(action.payload).forEach((key) => {
          newState[key] = action.payload[key];
        });
        return newState;
      case actions.SET_TODAYS_NEWS_LIST:
        return {
          ...state,
          todaysNewsList: action.payload,
        }
      case actions.SET_PRINT_NEWS_LIST:
        return {
          ...state,
          printNewsList: action.payload,
        }
      default:
        return state;
    }
  }, initialState);

  if (newState) {
    const options = {
      printEdition: newState.printEdition,
      darkTheme: newState.darkTheme,
      textSize: newState.textSize,
      workMode: newState.workMode,
      jsonMode: newState.jsonMode,
      jsonBodyMode: newState.jsonBodyMode,
      noImages: newState.noImages,
      reverse: newState.reverse,
      maxWidth: newState.maxWidth,
    };

    localStorage.setItem('appOptions', JSON.stringify(options));
  }

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { store, StateProvider, Consumer };